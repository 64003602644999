<template>
  <div class="custom-content-height">
    <div class="row my-0 mx-1 px-0 px-sm-4">
      <div
        class="poppins text-dark-65 font-size-h1 display5-md font-weight-bolder col-12 col-md-4"
      >
        My analytics
      </div>
      <div class="col-12 col-md-8 d-flex justify-end align-center flex-wrap">
        <FilterSelector
          :filters="filters"
          :update-data-of-charts="updateDataOfCharts"
        ></FilterSelector>
        <div class="col-12 col-md-3 ma-0 pa-0">
          <div>
            <v-select
              class="px-2"
              v-model="dateTypeTrigger"
              :items="dateTypes"
              item-text="text"
              item-value="value"
              label="Date"
              solo
              dense
              hide-details
            >
              <template v-slot:prepend-inner>
                <v-icon>mdi-calendar-range</v-icon>
              </template>
              <template v-slot:item="{ item }"
                ><v-btn
                  text
                  large
                  class="ma-0 pa-0 w-100"
                  @click="updateDateOfCharts(item.value)"
                >
                  <div class="w-100 text-left px-2">{{ item.text }}</div>
                </v-btn>
              </template>
              <!-- <template v-slot:item="{ item }"
                ><div
                  style="width: 100%"
                  @click="updateDateOfCharts(item.value)"
                >
                  {{ item.text }}
                </div>
              </template> -->
            </v-select>
            <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
              <v-date-picker
                v-model="dateRange"
                range
                scrollable
                :max="new Date().toISOString().split('T')[0]"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    () => {
                      dialog = false;
                      isVisible = true;
                      dateTypeTrigger = dateType;
                    }
                  "
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="updateDateOfCharts('custom')"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>

    <div>
      <!-- {{ userData }} -->
      <div class="row mx-0 mx-sm-1 mt-md-0 mt-6 px-2 px-sm-4" v-if="showData">
        <analytics-orders
          v-if="userData.length > 0"
          :item="userData[0]"
          :filterData="dataOfFilters"
          :exportChart="loadDataFromServer"
        ></analytics-orders>
        <analytics-inventory
          v-if="userData.length > 1"
          :item="userData[1]"
          :filterData="dataOfFilters"
          :exportChart="loadDataFromServer"
        ></analytics-inventory>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsOrders from "@/own/components/dashboard/AnalyticsOrders";
import AnalyticsInventory from "@/own/components/dashboard/AnalyticsInventory";
import FilterSelector from "@/own/components/dashboard/filters/FilterSelector";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "AnalyticsLayout",
  props: ["userData", "updateDashboardData", "filters"],
  components: { FilterSelector, AnalyticsOrders, AnalyticsInventory },
  data: () => ({
    dialog: false,
    dateTypeTrigger: "today",
    isVisible: true,
    dateType: "today",
    dateRange: [],
    filterData: {},
    dateTypes: [
      { text: "Today", value: "today" },
      { text: "This week", value: "this_week" },
      { text: "Last week", value: "last_week" },
      { text: "Last 7 days", value: "last_7_days" },
      { text: "This month", value: "this_month" },
      { text: "Last month", value: "last_month" },
      { text: "Last 30 days", value: "last_30_days" },
      { text: "This year", value: "this_year" },
      { text: "Last year", value: "last_year" },
      { text: "All time", value: "all_time" },
      { text: "Custom", value: "custom" },
    ],
  }),
  methods: {
    // allowedDates: (val) => parseInt(val.split("-")[2], 10) % 2 === 0,
    loadDataFromServer(url) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(url, this.dataOfFilters)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.url;
          link.download = "Export.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    updateDataOfCharts(val) {
      this.filterData[`${Object.entries(val)[0][0]}`] =
        Object.entries(val)[0][1];
      let data = { ...this.filterData };
      this.filterData = { ...data };
      this.updateDashboardData({
        date_range_type: this.dateType,
        date_range: this.dateRange,
        ...this.filterData,
      });
    },

    updateDateOfCharts(val) {
      if (this.dateType != val) {
        this.isVisible = false;
      }
      if (val == "custom") {
        if (!this.dialog) {
          this.dialog = true;
          this.isVisible = false;
          return;
        } else {
          this.dialog = false;

          this.dateType = this.dateTypeTrigger;
          // console.log("update 1");
          this.updateDashboardData({
            date_range_type: val,
            date_range: this.dateRange,
            ...this.filterData,
          });
          this.isVisible = true;
          return;
        }
      } else {
        // console.log("update 2", val, this.dateTypeTrigger, this.dateType);
        this.dateTypeTrigger = val;
        this.dateType = this.dateTypeTrigger;
        this.updateDashboardData({
          date_range_type: this.dateType,
          date_range: this.dateRange,
          ...this.filterData,
        });
        this.isVisible = true;
      }
    },
  },
  computed: {
    dataOfFilters() {
      const data = {
        date_range_type: this.dateType,
        date_range: this.dateRange,
        ...this.filterData,
      };
      return data;
    },
    showData: function () {
      return this.isVisible;
    },
    isDataReady: function () {
      return this.userData.length > 0;
    },
  },
};
</script>
